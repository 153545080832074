import React, {useEffect, useRef} from "react"
import Single from "../../single"
import info1 from "../../../../static/img/irstrat/articulo2.png";
import noticias08 from "../../../../static/img/blog/noticias-08-.png";

const Detail = (props) => {
    const isMountedComponent = useRef(true)
    useEffect(() => {
        if (isMountedComponent.current) {
            window.jquery_scripts()
        }
        return () => {
            isMountedComponent.current = false
        }
    })

    return (
        <Single location={props.location}
                title='Mejores Prácticas de Desarrollo de Reportes de Sostenibilidad y de Emisiones de Carbono en Canadá y Estados Unidos'>
            <div className="main_blog_items">
                <div className="main_blog_item">
                    <div className="main_blog_image img_hover">
                        <img alt={" "} src={noticias08}/>
                    </div>
                    <div className="main_blog_text">
                        <div className="post_info">
                            <a href="#" className="date">
                                18 Nov. 2020
                            </a>
                            <div className="blog_author_area">
                 <span>
                  Por : J.A Lovera
                </span>
                                <span>
                    Comunicación
                </span>
                            </div>
                        </div>

                        <h2>Mejores Prácticas de Desarrollo de Reportes de Sostenibilidad y de Emisiones de Carbono en
                            Canadá y Estados Unidos</h2>
                        <br/>
                    </div>
                </div>
                <div className="s_main_text">
                    <p>El término “Reporte de sostenibilidad”, se usa generalmente para abarcar una variedad de reportes
                        corporativos, que incluyen informes de Responsabilidad Social Empresarial, de Inversión
                        Socialmente Responsable y de impacto ambiental.</p>
                    <p>De acuerdo con el estudio más reciente de Millani, consultoría especializada en el asesoramiento
                        sobre temas relacionados con la Inversión Socialmente Responsable (ESG, por sus siglas en
                        inglés),
                        aproximadamente el 58% de las empresas que integran el índice compuesto S&P/TSX, había publicado
                        un Reporte de Sostenibilidad en 2019, lo que equivale a un aumento de 10% respecto al
                        año previo; y, para el próximo ejercicio, estiman que el 66% de las empresas que componen este
                        índice publiquen un Reporte de Sostenibilidad.</p>
                    <p>Sin embargo, si se contempla tanto a las empresas que publican un Reporte de Sostenibilidad como
                        a las que discuten cuestiones de ESG en sus sitios web, la cifra aumenta al 89%, lo que
                        representa un
                        incremento considerable en comparación con los tres años anteriores. En la siguiente gráfica se
                        presentan los respectivos porcentajes de las empresas del índice compuesto S&P/TSX que
                        publicaron un informe de sostenibilidad o información ESG en sus sitios web. </p>
                    <img className="w-100 wow fadeIn" src={info1} alt="" data-wow-delay="0.2s"
                         data-wow-duration="2.0s"/>
                    <p>A pesar de registrar una tendencia alcista en este ámbito, Canadá, aún, se encuentra por debajo
                        de Estados Unidos, ya que el 90% de las compañías que conforman el índice S&P 500 publicaron un
                        Reporte de Sostenibilidad en el 2019,
                        según la investigación anual de sostenibilidad S&P 500 del Governance & Accountability
                        Institute. </p>
                    <p><b>Marcos de referencia para la elaboración de Reportes de Sostenibilidad</b></p>
                    <p>Un aspecto importante para considerar al momento de realizar un Reporte de Sostenibilidad es
                        decidir qué marco se empleará para su elaboración. Debido a ello, existen compañías que se
                        encargan
                        de definir estándares y lineamientos para ayudar a las empresas a identificar, gestionar e
                        informar sobre los temas de sostenibilidad que más interesan a sus inversores.
                        De las empresas que publicaron un reporte de sostenibilidad este año en Canadá, el 64% utilizó
                        el
                        marco de la Iniciativa de Información Global (GRI), un aumento de 59% con respecto al año
                        previo; mientras que un 36% utilizó el marco de la Junta de Normas de Contabilidad para la
                        Sostenibilidad
                        (SASB), lo que representó un incremento considerable en comparación con el 6% del año
                        pasado.</p>
                    <p>Ante el diferente enfoque con el que fueron creados los marcos mencionados y como factor de
                        apoyo, la SASB y GRI anunciaron una colaboración con motivo de ayudar a las compañías a
                        identificar
                        la forma en que los dos marcos pueden complementarse entre sí para mejorar la calidad de los
                        informes de sostenibilidad.</p>
                    <p>Asimismo, al transitar hacia una economía más preocupada por las emisiones de carbono, las
                        empresas revelan cada vez más las medidas que están adoptando al respecto. Millani reportó que
                        el
                        30% de las empresas del índice compuesto S&P/TSX utilizan el marco del Grupo de Trabajo sobre
                        divulgación de Información Financiera relacionada con el Clima (TCFD) y otro 17% lo mencionan
                        para
                        reconocer su valor o comprometerse a utilizarlo en el futuro. </p>
                    <p>Derivado de las opciones de marcos que se pueden emplear en la elaboración de los reportes y la
                        existente confusión de sus diferencias, en el “Dialogó de los Reportes Empresariales” del 2019,
                        se
                        aclaró que existen altos niveles de concordancia entre los marcos de GRI, SASB, Carbon
                        Disclosure Project (CDP) y las recomendaciones del TCFD, que resulta fundamental considerar a
                        los reportes ESG
                        como un medio de comunicación e identificador de prioridades de las empresas. </p>
                    <p>Referencias Bibliográficas
                        <ul>
                            <li>Ashwell, B. (2020). Rise in Canadian companies publishing sustainability reports.
                                Septiembre 9, 2020. <a
                                    href="https://www.irmagazine.com/esg/rise-canadian-companies-publishing-sustainability-reports"
                                    target="_blank"> IR Magazine</a>.
                            </li>
                            <li>Millani. (2020). Millani’s Annual ESG Disclosure Study: A Canadian Perspective.
                                Septiembre 8, 2020. <a
                                    href="https://corostrandberg.com/wp-content/uploads/2020/09/millani-annual-esg-disclosure-study-2020.pdf"
                                    target="_blank"> Millani</a>.
                            </li>
                        </ul>
                    </p>

                </div>
            </div>
        </Single>
    )
}

export default Detail
